import React, { useMemo, useCallback, useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts';

// Constants
const COLORS = {
  PROJECTED: {
    PAST: '#E5E5E5',
    FUTURE: '#929AAB',
  },
  ACTUAL: {
    PAST: '#000000',
    FUTURE: '#474A56',
  },
};

const MONTHS = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11,
};

// Custom Components
// Modified to only render line when there's a value
const CustomLabel = ({ x, y, width, value }) => {
  if (!value) return null; 
  return (
    <g>
      <line x1={x} y1={y} x2={x + width} y2={y} stroke="#000" strokeWidth={3} />
    </g>
  );
};

const CustomTick = ({ x, y, payload, yearInfo, tickWidth, isMobile }) => {
  const isYearStart = yearInfo && payload.index === yearInfo.startPosition;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#000"
        fontSize={isMobile ? '12px' : '15px'}
        fontWeight="bold"
      >
        {payload.value}
      </text>
      {isYearStart && (
        <line
          x1={isMobile ? -tickWidth / 3.6 : -tickWidth / 2}
          y1={isMobile ? -4 : -8}
          x2={isMobile ? -tickWidth / 3.6 : -tickWidth / 2}
          y2={isMobile ? 30 : 50}
          stroke="#000"
          strokeWidth={isMobile ? 1 : 2}
        />
      )}
    </g>
  );
};

// Helper functions
const getYearLabelsAndSeparators = (data) => {
  if (!data?.length) return [];

  const yearGroups = data.reduce((acc, item) => {
    if (!acc[item.year]) acc[item.year] = [];
    acc[item.year].push(item);
    return acc;
  }, {});

  let currentIndex = 0;
  return Object.entries(yearGroups).map(([year, items]) => {
    const startIndex = currentIndex;
    const length = items.length;
    currentIndex += length;

    return {
      year,
      centerIndex: startIndex + Math.floor(length / 2),
      startPosition: startIndex,
      endPosition: startIndex + length - 1,
    };
  });
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return isMobile;
};

const BarChartComponent = ({
  data = [],
  actualDataKey = 'actual',
  projectedDataKey = 'projected',
}) => {
  const isMobile = useIsMobile();

  const currentDate = useMemo(() => {
    const date = new Date();
    return {
      month: date.toLocaleString('default', { month: 'short' }),
      year: date.getFullYear(),
    };
  }, []);

  const yearLabelsAndSeparators = useMemo(
    () => getYearLabelsAndSeparators(data),
    [data]
  );

  const isFutureOrCurrentMonth = useCallback(
    (dataPoint) => {
      if (!dataPoint?.year) return false;

      const dataYear = parseInt(dataPoint.year);
      const dataMonthIndex = MONTHS[dataPoint.month];
      const currentMonthIndex = MONTHS[currentDate.month];

      return (
        dataYear > currentDate.year ||
        (dataYear === currentDate.year && dataMonthIndex >= currentMonthIndex)
      );
    },
    [currentDate]
  );

  const [opacity, setOpacity] = React.useState({
    [actualDataKey]: 1,
    [projectedDataKey]: 1,
  });

  const handleLegendMouseEvent = useCallback((dataKey, value) => {
    setOpacity((prev) => ({ ...prev, [dataKey]: value }));
  }, []);

  const renderCell = useCallback(
    ({ entry, index, dataKey, isProjected }) => (
      <Cell
        key={`${isProjected ? 'proj' : 'actual'}-cell-${index}`}
        fill={
          isProjected
            ? isFutureOrCurrentMonth(entry)
              ? COLORS.PROJECTED.FUTURE
              : COLORS.PROJECTED.PAST
            : isFutureOrCurrentMonth(entry)
              ? COLORS.ACTUAL.FUTURE
              : COLORS.ACTUAL.PAST
        }
        fillOpacity={opacity[dataKey]}
        stroke={
          isProjected
            ? isFutureOrCurrentMonth(entry)
              ? COLORS.PROJECTED.FUTURE
              : COLORS.PROJECTED.PAST
            : isFutureOrCurrentMonth(entry)
              ? COLORS.ACTUAL.FUTURE
              : COLORS.ACTUAL.PAST
        }
        strokeWidth={2}
      />
    ),
    [isFutureOrCurrentMonth, opacity]
  );

  const containerStyle = {
    width: '100%',
    ...(isMobile && {
      position: 'relative',
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
    }),
  };

  const chartContainerStyle = {
    ...(isMobile && {
      minWidth: '768px',
      position: 'relative',
    }),
  };

  return (
    <div style={containerStyle}>
      <div style={chartContainerStyle}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={data}
            margin={
              isMobile
                ? { top: 5, right: 15, left: 15, bottom: 20 }
                : { top: 5, right: 30, left: 20, bottom: 25 }
            }
          >
            <CartesianGrid horizontal={false} vertical={false} />
            <XAxis
              dataKey="month"
              axisLine={true}
              tickLine={true}
              tick={(props) => (
                <CustomTick
                  {...props}
                  isMobile={isMobile}
                  yearInfo={yearLabelsAndSeparators.find(
                    (yl) =>
                      yl.startPosition <= props.index &&
                      props.index <= yl.endPosition
                  )}
                  tickWidth={1250 / (props.visibleTicksCount || 1)}
                />
              )}
              height={isMobile ? 45 : 60}
            />
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              tick={(props) => {
                const yearLabel = yearLabelsAndSeparators.find(
                  (yl) => yl.centerIndex === props.index
                );
                return yearLabel ? (
                  <text
                    x={props.x}
                    y={props.y}
                    textAnchor="middle"
                    fill="#000"
                    fontSize="15px"
                    fontWeight="bold"
                  >
                    {yearLabel.year}
                  </text>
                ) : null;
              }}
              xAxisId="years"
            />
            <YAxis
              width={0}
              orientation="right"
              tickCount={0}
              axisLine={false}
              tick={false}
            />
            <Tooltip cursor={{ fill: 'transparent' }} />
            <Legend
              onMouseEnter={(o) => handleLegendMouseEvent(o.dataKey, 0.5)}
              onMouseLeave={(o) => handleLegendMouseEvent(o.dataKey, 1)}
            />
            {data.length > 0 && (
              <>
                <Bar
                  dataKey={projectedDataKey}
                  name="Projected Repayment Value"
                  barSize={40}
                  fill={COLORS.PROJECTED.PAST}
                  legendType="square"
                >
                  {data.map((entry, index) =>
                    renderCell({
                      entry,
                      index,
                      dataKey: projectedDataKey,
                      isProjected: true,
                    })
                  )}
                  <LabelList dataKey={projectedDataKey} content={CustomLabel} />
                </Bar>
                <Bar
                  dataKey={actualDataKey}
                  name="Actual Repayment Value"
                  barSize={40}
                  fill={COLORS.ACTUAL.PAST}
                  legendType="square"
                >
                  {data.map((entry, index) =>
                    renderCell({
                      entry,
                      index,
                      dataKey: actualDataKey,
                      isProjected: false,
                    })
                  )}
                  <LabelList dataKey={actualDataKey} content={CustomLabel} />
                </Bar>
              </>
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChartComponent;
