import React from 'react';
import { BasicTable } from '../../../components/tables';
import { formatCurrency } from '../../../utils/formatUtils';
import { Cell } from 'recharts';
const LoanTab = ({ loansData }) => {
  let mappedLoansData = loansData
    ? loansData.map((loan) => ({
        loanName: loan?.name || 'N/A',
        poolName: loan?.lending_pool.pool_name,
        amount: formatCurrency(loan?.amount || 0),
        interestRate: `${loan?.interest_rate}%` || 'N/A',
        cycle: `${loan?.term_in_days} days` || 'N/A',
        date:
          loan?.due_date ||
          (loan?.last_disbursement?.repayment_date
            ? new Date(loan?.last_disbursement?.repayment_date)
                .toISOString()
                .split('T')[0]
            : '' || 'N/A'),
        status: loan?.status === 'CREATED' ? 'ACTIVE' : loan?.status || 'N/A',
      }))
    : [];

  mappedLoansData = mappedLoansData.sort((a, b) => {
    if (a.date > b.date) {
      return -1;
    } else if (a.date < b.date) {
      return 1;
    }
  });

  return (
    <div>
      <BasicTable
        data={mappedLoansData}
        columns={[
          {
            header: 'Name',
            accessor: 'poolName',
          },
          {
            header: 'Amount (USD)',
            accessor: 'amount',
          },
          {
            header: 'Interest Rate',
            accessor: 'interestRate',
          },
          {
            header: 'Loan Cycle',
            accessor: 'cycle',
          },
          {
            header: 'Due Date',
            accessor: 'date',
          },
          {
            header: 'Status',
            accessor: 'status',
            Cell: ({ cell: { value } }) => (
              <div className="status-cell d-md-flex align-items-md-center justify-content-md-center">
                {value}
              </div>
            ),
          },
        ]}
        isSortable={false}
        isPaginated={mappedLoansData?.length > 5}
        pageSize={5}
        currentPage={0}
        showCheckbox={false}
      />
    </div>
  );
};

export default LoanTab;
