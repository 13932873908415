import { createApi } from '@reduxjs/toolkit/query/react';
import { publicBaseQuery } from './baseQueries';

export const tradeFiAPI = createApi({
  reducerPath: 'tradeFiAPI',
  baseQuery: publicBaseQuery,
  tagTypes: ['LendingPool', 'Loan', 'Borrower', 'Transaction', 'Agreement'],
  endpoints: (builder) => ({
    getAllLendingPool: builder.query({
      query: () => '/api/templates/tradefi/lending_pool_group/',
      providesTags: ['LendingPool'],
    }),
    getGraphData: builder.query({
      query: ({ id, year }) => {
        let baseUrl = `/api/templates/tradefi/lending_pool_group/${id}/repayments/`;
        if (year) {
          baseUrl += `?year=${year}`;
        }
        return baseUrl;
      },
      providesTags: ['LendingPool'],
    }),
    getLendingPoolById: builder.query({
      query: (poolid) => `/api/templates/tradefi/lending_pool_group/${poolid}/`,
      providesTags: ['LendingPool'],
    }),
    getLoansInLendingPool: builder.query({
      query: (id) => `/api/templates/tradefi/lending_pool_group/${id}/loans/`,
      providesTags: ['Loan'],
    }),
    getAllLoanInfo: builder.query({
      query: () => '/api/templates/tradefi/lending_pool/all-loan-info/',
      providesTags: ['Loan'],
    }),
    getOverviewOfAPool: builder.query({
      query: (id) =>
        `/api/templates/tradefi/lending_pool_group/${id}/overview/`,
      providesTags: ['LendingPool'],
    }),
    getAllBorrowersInfo: builder.query({
      query: (id) =>
        `/api/templates/tradefi/lending_pool_group/${id}/borrowers/`,
      providesTags: ['Borrower'],
    }),
    getAllRecentActivity: builder.query({
      query: (id) =>
        `/api/templates/tradefi/lending_pool_group/${id}/tx-history/`,
      providesTags: ['Transaction'],
    }),
    getLendingPoolAgreements: builder.query({
      query: (id) =>
        `/api/templates/tradefi/lending_pool_group/${id}/lending-agreements/`,
      providesTags: ['Agreement'],
    }),
  }),
});

export const {
  useGetAllLendingPoolQuery,
  useGetGraphDataQuery,
  useGetLendingPoolByIdQuery,
  useGetLoansInLendingPoolQuery,
  useGetAllLoanInfoQuery,
  useGetOverviewOfAPoolQuery,
  useGetAllBorrowersInfoQuery,
  useGetAllRecentActivityQuery,
  useGetLendingPoolAgreementsQuery,
} = tradeFiAPI;
